body {
  background-color: #f5f5f5;
  color: #4e4e4e;
}

h1, h2, h3, h4, h5, h6 {
  color: #1e1e1e;
}

a {
  color: #1e1e1e;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

a:hover {
  color: #0078ff;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.p-r {
  position: relative;
}

.color-a {
  text-decoration: none;
  color: #0078ff;
}

.color-d {
  color: #f5f5f5;
}

.color-text-a {
  text-decoration: none;
  color: #4e4e4e;
}

.box-shadow, .card-blog, .work-box, .service-box, .paralax-mf {
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

.box-shadow-a, .button:hover {
  text-decoration: none;
  box-shadow: 0 0 0 4px #cde1f8;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  overflow: hidden;
}

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 1024px) {
  .bg-image {
      background-attachment: scroll;
  }
}

.overlay-mf {
  background-color: #0078ff;
}

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.paralax-mf {
  position: relative;
  padding: 8rem 0;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/*--/ Sections /--*/
.sect-4 {
  padding: 4rem 0;
}

.sect-pt4 {
  padding-top: 4rem;
}

.sect-mt4 {
  margin-top: 4rem;
}

/*--/ Title s /--*/
.title-s {
  font-weight: 600;
  color: #1e1e1e;
  font-size: 1.1rem;
}

/*--/ Title A /--*/
.title-box {
  margin-bottom: 4rem;
}

.title-a {
  text-decoration: none;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle-a {
  text-decoration: none;
  color: #4e4e4e;
}

.line-mf {
  width: 40px;
  height: 5px;
  background-color: #0078ff;
  margin: 0 auto;
}

/*--/ Title Left /--*/
.title-box-2 {
  margin-bottom: 3rem;
}

.title-left {
  font-size: 2rem;
  position: relative;
}

.title-left:before {
  content: "";
  position: absolute;
  height: 3px;
  background-color: #0078ff;
  width: 100px;
  bottom: -12px;
}

/*------/ Box /------*/
.box-pl2 {
  padding-left: 2rem;
}

.box-shadow-full {
  padding: 3rem 1.25rem;
  position: relative;
  background-color: #fff;
  margin-bottom: 3rem;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .box-shadow-full {
      padding: 3rem;
  }
}

/*------/ Socials /------*/
.socials {
  padding: 1.5rem 0;
}

.socials ul li {
  display: inline-block;
}

.socials .ico-circle {
  height: 40px;
  width: 40px;
  font-size: 1.2rem;
  border-radius: 50%;
  line-height: 1.6;
  margin: 0 15px 0 0;
  box-shadow: 0 0 0 3px #0078ff;
  transition: all 500ms ease;
}

.socials .ico-circle:hover {
  background-color: #0078ff;
  color: #fff;
  box-shadow: 0 0 0 3px #cde1f8;
  transition: all 500ms ease;
}

/*------/ Ul resect /------*/
.ul-resect, .widget-tags ul, .widget-sidebar .list-sidebar, .box-comments .list-comments, .blog-wrapper .post-meta ul, .list-ico, .socials ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-ico {
  line-height: 2;
}

.list-ico span {
  color: #0078ff;
  margin-right: 10px;
}

/*------/ Ico Circle /------*/
.ico-circle {
  height: 100px;
  width: 100px;
  font-size: 2rem;
  border-radius: 50%;
  line-height: 1.55;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 0 10px #0078ff;
  display: block;
}

/*------/ Owl Carousel /------*/
.owl-theme .owl-dots {
  text-align: center;
  margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 7px;
  margin: 5px 5px;
  background: #cde1f8;
  border: 0px solid #cde1f8;
  display: block;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #cde1f8;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #1B1B1B;
  width: 25px;
}

/*--/ Scrolltop s /--*/
.scrolltop-mf {
  position: relative;
  display: none;
}

.scrolltop-mf span {
  z-index: 999;
  position: fixed;
  width: 42px;
  height: 42px;
  background-color: #0078ff;
  opacity: 0.7;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  top: auto;
  left: auto;
  right: 30px;
  bottom: 50px;
  cursor: pointer;
  border-radius: 50%;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0078ff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #298eff;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*------/ Prelaoder /------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #0078ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
/*------/ Button /------*/
.button {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  transition: all 500ms ease;
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background-color: #0062d3;
  color: #fff;
  transition: all 500ms ease;
}

.button-a {
  text-decoration: none;
  background-color: #0078ff;
  color: #fff;
  border-color: #cde1f8;
}

.button-big {
  padding: 0.9rem 2.3rem;
  font-size: 1.2rem;
}

.button-rouded {
  border-radius: 5rem;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
