#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

#header .language {
  margin: 0;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
}

#header .language .name {
  padding-left: 2px;
}

#header .logo a {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  max-height: 40px;
}

#header.header-scrolled {
  background: rgba(0, 0, 0, 0.9);
  padding: 12px 0;
}

.navbar-desktop {
  padding: 0;
}

.navbar-desktop ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar-desktop li {
  position: relative;
}

.navbar-desktop > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 30px;
}

.navbar-desktop a, .navbar-desktop a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  white-space: nowrap;
  transition: 0.3s;
  letter-spacing: 0.4px;
  position: relative;
  text-transform: uppercase;
}

.navbar-desktop a i, .navbar-desktop a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar-desktop > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  width: 0;
  background-color: #fff;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-desktop a:hover:before, .navbar-desktop li:hover > a:before, .navbar-desktop .active:before {
  visibility: visible;
  width: 80%;
}

.navbar-desktop a:hover, .navbar-desktop .active, .navbar-desktop .active:focus, .navbar-desktop li:hover > a {
  color: #fff;
}

.navbar-desktop .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar-desktop .dropdown ul li {
  min-width: 200px;
}

.navbar-desktop .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #4e4e4e;
}

.navbar-desktop .dropdown ul a i {
  font-size: 12px;
}

.navbar-desktop .dropdown ul a:hover, .navbar-desktop .dropdown ul .active:hover, .navbar-desktop .dropdown ul li:hover > a {
  color: #0078ff;
}

.navbar-desktop .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar-desktop .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar-desktop .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar-desktop .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar-desktop .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar-desktop ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(78, 78, 78, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile > ul > li {
  padding: 0;
}

.navbar-mobile a:hover:before, .navbar-mobile li:hover > a:before, .navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #4e4e4e;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #0078ff;
}

.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #0078ff;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}