.service-box {
    background-color: #fff;
    padding: 2.5rem 1.3rem;
    border-radius: 1rem;
    margin-bottom: 3rem;
    text-align: center;
}

.service-box:hover .ico-circle {
    transition: all 500ms ease;
    color: #fff;
    background-color: #0078ff;
    box-shadow: 0 0 0 10px #cde1f8;
}

.service-box .service-ico {
    margin-bottom: 1rem;
    color: #1e1e1e;
}

.service-box .ico-circle {
    transition: all 500ms ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.service-box .ico-circle i {
    line-height: 0;
    font-size: 40px;
}

.service-box .s-title {
    font-size: 1.4rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4rem 0;
}

.service-box .s-description {
    color: #4e4e4e;
}