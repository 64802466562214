.footer-paralax {
    padding: 4rem 0 0 0;
    background-image: url('../../../public/images/overlay-bg.jpg');
}

.contact-mf {
    margin-top: 4rem;
}

@media (min-width: 767px) {
    .contact-mf .box-pl2 {
        margin-top: 3rem;
        padding-left: 0rem;
    }
}

.php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.php-email-form #empty-input {
    display: none;
}

.php-email-form .sent-message {
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.php-email-form .loading {
    background: #fff;
    text-align: center;
    padding: 15px;
}

.php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.php-email-form input, .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.php-email-form input:focus, .php-email-form textarea:focus {
    border-color: #0078ff;
}

.php-email-form input {
    padding: 10px 15px;
}

.php-email-form textarea {
    padding: 12px 15px;
    margin-bottom: 0;
}

.php-email-form button[type=submit] {
    background: #0078ff;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
}

.php-email-form button[type=submit]:hover {
    background: #3393ff;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}