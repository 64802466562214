.about-mf .box-shadow-full {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.about-mf .about-img {
  margin-bottom: 2rem;
}

.about-mf .about-img img {
  margin-left: 10px;
}

@media (min-width: 767px) {
  .about-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }
}

.skill-mf span {
  color: #4e4e4e;
}

.skill-mf .tech {
  /* margin: 0.5rem 0 1.2rem 0;
  border-radius: 0;
  height: 0.7rem; */
  margin-top: 5%;
}

.skill-mf .tech i {
  font-size: 40px;
  margin: 5px;
}

@media (max-width: 992px) {
  .skill-mf .tech i {
    font-size: 25px;
    margin: 5px;
  }
}

@media (max-width: 768px) {
  .skill-mf .tech i {
    font-size: 40px;
    margin: 5px;
  }
}
